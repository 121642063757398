import { colors, ParagraphText, StyledLink, VerticalSpace } from '@imtbl/design-system';
import React, { useEffect } from 'react';

import { EthNetwork } from '../../lib';
import { sendAnalytics } from '../../lib/analytics/send-analytics';
import { ScreenEventName } from '../../lib/analytics/types';
import { createScreenEvent } from '../../lib/analytics/utils';

interface ChangeNetworkProps {
    network: EthNetwork;
}

export const ChangeNetwork = ({ network }: ChangeNetworkProps) => {
    useEffect(() => {
        sendAnalytics(createScreenEvent(ScreenEventName.errorNetworkOpened));
    }, []);

    return (
        <>
            <VerticalSpace bottom="large" top="large">
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    Connect your wallet to <strong style={{ color: colors.light[100] }}>{network}</strong> network.
                    <br />
                    If you are already on <strong style={{ color: colors.light[100] }}>{network}</strong>, this can also
                    be caused by an experimental feature in the latest Metamask release (12.x), which is currently
                    incompatible with the Immutable X Link. To resolve this issue, open the Metamask extension, click
                    the dots in the top right, navigate to Settings - Experimental, and turn OFF the toggle marked
                    &apos;Select networks for each site&apos;.
                </ParagraphText>
            </VerticalSpace>

            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                For further assistance, please visit our{' '}
                <StyledLink
                    href="https://support.immutable.com/hc/en-us/articles/9456077819919-Wallet-connection-troubleshooting"
                    fillColor={colors.light[700]}
                    testId="supportLink"
                    fontWeight="bold"
                    target="_blank"
                    fontSize="small"
                >
                    support page
                </StyledLink>
                .
            </ParagraphText>
        </>
    );
};
