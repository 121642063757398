import { Web3Provider } from '@ethersproject/providers';
import {
    colors,
    EllipsizedWalletLine,
    ExplodingCreditCard,
    FlexLayout,
    HorizontalLoader,
    IndeterminateHorizontalLines,
    InlineEllipsizedWalletLine,
    layoutHelpers,
    ParagraphText,
    SectionHeading,
    SimpleText,
    StandardButton,
    StyledLink,
    Tickbox,
    VerticalSpace,
} from '@imtbl/design-system';
import { ProviderPreference } from '@imtbl/imx-sdk';
// import WalletConnectProvider from '@walletconnect/web3-provider';
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { useLaunchDarklyFlags } from '../../context/LaunchDarkly';
import { useI18nMessaging } from '../../hooks/useI18nMessaging.hook';
import { DispatchSetError, EthNetwork, LinkConfig, ParentWindow } from '../../lib';
import { sendAnalytics } from '../../lib/analytics/send-analytics';
import { ButtonEventName, LinkEventName, ScreenEventName } from '../../lib/analytics/types';
import { createButtonEvent, createLinkEvent, createScreenEvent } from '../../lib/analytics/utils';
import { FEATURE_FLAG } from '../../lib/featureFlags';
import { InternalRoutes } from '../../utils/internalRoutes';
import { StandardLinkRouteButtonsFooter, StandardLinkRouteContainer } from '../common';
import DifferentWalletButton from '../SetupMagicWallet/DifferentWalletButton';
import styles from './index.module.css';
import { RegisterState, SetupStep, useSetup, WalletState } from './setupHook';
import { TermsAndConditions } from './TermsAndConditions';
import { GamestopExternalProvider } from './useGamestopConnector';

export type SetupProps = {
    config: LinkConfig;
    parent: ParentWindow;
    provider: Web3Provider;
    setErrorLog: DispatchSetError;
    network: EthNetwork;
    stepOverride?: SetupStep;
    walletOverride?: WalletState;
    registrationOverride?: RegisterState;
    providerPreference?: ProviderPreference;
};

const TERMS_URL_HREF =
    'https://support.immutable.com/hc/en-us/articles/9405981309199-Immutable-X-Third-Party-Digital-Wallets';

type ChangeWalletButtonProps = {
    providerPreference?: ProviderPreference;
    onClick: () => void;
};

const ChangeWalletButton = ({ providerPreference, onClick }: ChangeWalletButtonProps) =>
    providerPreference !== ProviderPreference.GAMESTOP ? (
        <StyledLink
            testId="Setup__ChangeWalletButton"
            fillColor={colors.light[700]}
            fontWeight="bold"
            onClick={onClick}
            fontSize="small"
        >
            (Change)
        </StyledLink>
    ) : (
        <></>
    );

export const Setup = ({
    config,
    parent,
    provider,
    setErrorLog,
    network,
    stepOverride,
    walletOverride,
    registrationOverride,
    providerPreference,
}: SetupProps) => {
    const messages = useI18nMessaging({});
    const flags = useLaunchDarklyFlags();
    const enableHardwareWalletInfo = flags[FEATURE_FLAG.ENABLE_HARDWARE_WALLET_INFO];
    const enableWalletConnect = flags[FEATURE_FLAG.ENABLE_WALLETCONNECT_FOR_MOBILE_WALLET];
    const enableGamestop = flags[FEATURE_FLAG.ENABLE_GAMESTOP_WALLET];

    // @NOTE so testing individual stages can be done without mocking each stage
    const [currentStep, setCurrentStep] = React.useState(stepOverride);
    const {
        step = stepOverride,
        wallet = walletOverride,
        registration = registrationOverride,
        changeWallet,
        connectWallet,
        register,
        finish,
        agreeToTnC,
        changeAgreeToTnc,
    } = useSetup({
        config,
        parent,
        provider,
        setErrorLog,
        network,
        providerPreference,
    });

    useEffect(() => {
        const newStep = stepOverride || step;

        if (newStep) {
            setCurrentStep(newStep);
        }
    }, [step]);

    useEffect(() => {
        if (wallet && currentStep === 'signIn') {
            connectWallet();
        }
    }, [wallet, currentStep]);

    if (!enableGamestop && providerPreference === ProviderPreference.GAMESTOP) {
        setErrorLog(
            new Error(`${FEATURE_FLAG.ENABLE_GAMESTOP_WALLET} is disabled`),
            messages.setup.error.featureNotYetSupported,
        );

        return null;
    }

    const { isMetaMask } = provider.provider;
    const { isGamestop } = provider.provider as GamestopExternalProvider;
    // const isWalletConnect = provider.provider instanceof WalletConnectProvider;

    const isNotMetamaskButWantMetamask = providerPreference === ProviderPreference.METAMASK && !isMetaMask;
    const isNotGamestopButWantGamestop = providerPreference === ProviderPreference.GAMESTOP && !isGamestop;

    if (isNotMetamaskButWantMetamask || isNotGamestopButWantGamestop) {
        return <Redirect to={InternalRoutes.MULTIPLE_WALLETS} />;
    }

    if (!(isMetaMask || isGamestop)) {
        return <Redirect to={InternalRoutes.MISSING_WALLET} />;
    }

    const buttonEventName =
        providerPreference === ProviderPreference.METAMASK
            ? ButtonEventName.selfCustodialMetaMaskChangePressed
            : ButtonEventName.selfCustodialGamestopChangePressed;

    function setupComplete() {
        sendAnalytics(createScreenEvent(ScreenEventName.setupCompleteOpened));

        return true;
    }

    return (
        <StandardLinkRouteContainer>
            {currentStep === 'loading' && (
                <HorizontalLoader backgroundColor={colors.dark[300]} className={styles.progressLoader} />
            )}

            {(currentStep === 'selectWallet' || currentStep === 'signIn') &&
                (enableWalletConnect ? (
                    <>
                        <section>
                            <VerticalSpace bottom="large">
                                <SectionHeading>Unlock Immutable X</SectionHeading>
                            </VerticalSpace>
                            <VerticalSpace bottom="large">
                                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                                    To manage your assets you&apos;ll be asked to:
                                </ParagraphText>
                            </VerticalSpace>
                            <VerticalSpace bottom="small">
                                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                                    <SimpleText fillColor={colors.blue[300]} className={styles.circle}>
                                        1
                                    </SimpleText>
                                    <strong style={{ color: colors.light[100] }}>Connect</strong> a wallet account
                                </ParagraphText>
                            </VerticalSpace>
                            <VerticalSpace bottom="small">
                                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                                    <SimpleText fillColor={colors.blue[300]} className={styles.circle}>
                                        2
                                    </SimpleText>
                                    <strong style={{ color: colors.light[100] }}>Sign </strong> to give access
                                </ParagraphText>
                            </VerticalSpace>
                            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                                <SimpleText fillColor={colors.blue[300]} className={styles.circle}>
                                    3
                                </SimpleText>
                                <strong style={{ color: colors.light[100] }}>Set up</strong> an Immutable X Key
                            </ParagraphText>
                        </section>
                        {enableHardwareWalletInfo && (
                            <VerticalSpace top="large">
                                <ParagraphText
                                    fillColor={colors.light[300]}
                                    fontSize="small"
                                    testId="setup-hardware-wallet"
                                >
                                    <strong style={{ color: colors.yellow[300] }}>Hardware wallet users:</strong> Ensure
                                    your wallet has been{' '}
                                    <StyledLink
                                        href={InternalRoutes.SETUP_HARDWARE_WALLET}
                                        fontSize="small"
                                        fontWeight="bold"
                                        underline
                                    >
                                        set up correctly
                                    </StyledLink>{' '}
                                    before connecting.
                                </ParagraphText>
                            </VerticalSpace>
                        )}
                        <StandardLinkRouteButtonsFooter>
                            <TermsAndConditions />
                            <VerticalSpace top="large">
                                <StandardButton
                                    testId="wallet-connect"
                                    buttonKind="ultimate-cta"
                                    onClick={() => {
                                        sendAnalytics(createButtonEvent(ButtonEventName.setupUnlockConnectPressed));
                                        changeWallet();
                                    }}
                                >
                                    Get Started
                                </StandardButton>
                            </VerticalSpace>
                            <DifferentWalletButton analyticsEvent={buttonEventName} />
                        </StandardLinkRouteButtonsFooter>
                    </>
                ) : (
                    <>
                        <section>
                            <FlexLayout
                                justifyContent="center"
                                alignItems="center"
                                paddingTop={layoutHelpers.gridUnits(5)}
                                paddingBottom={layoutHelpers.gridUnits(5)}
                            >
                                <img src="/images/lock.svg" alt="Setup Immutable X" className={styles.lock} />
                            </FlexLayout>
                            <VerticalSpace bottom="large">
                                <SectionHeading>Unlock Immutable X</SectionHeading>
                            </VerticalSpace>
                            <VerticalSpace bottom="large">
                                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                                    Before you can manage your inventories and start trading, we’ll need to set some
                                    things up.
                                </ParagraphText>
                            </VerticalSpace>
                            <VerticalSpace bottom="small">
                                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                                    <SimpleText fillColor={colors.blue[300]} className={styles.circle}>
                                        1
                                    </SimpleText>
                                    <strong style={{ color: colors.light[100] }}>Connect</strong> your Ethereum wallet
                                </ParagraphText>
                            </VerticalSpace>
                            <VerticalSpace bottom="small">
                                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                                    <SimpleText fillColor={colors.blue[300]} className={styles.circle}>
                                        2
                                    </SimpleText>
                                    <strong style={{ color: colors.light[100] }}>Sign in</strong> with your Ethereum
                                    wallet
                                </ParagraphText>
                            </VerticalSpace>
                            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                                <SimpleText fillColor={colors.blue[300]} className={styles.circle}>
                                    3
                                </SimpleText>
                                <strong style={{ color: colors.light[100] }}>Set up</strong> your Immutable X Key
                            </ParagraphText>
                        </section>
                        {enableHardwareWalletInfo && (
                            <ParagraphText
                                fillColor={colors.light[300]}
                                fontSize="small"
                                testId="setup-hardware-wallet"
                            >
                                <strong style={{ color: colors.yellow[300] }}>Hardware wallet users:</strong> Ensure
                                your wallet has been{' '}
                                <StyledLink
                                    href={InternalRoutes.SETUP_HARDWARE_WALLET}
                                    fontSize="small"
                                    fontWeight="bold"
                                    underline
                                >
                                    set up correctly
                                </StyledLink>{' '}
                                before connecting.
                            </ParagraphText>
                        )}
                        <StandardLinkRouteButtonsFooter>
                            <StandardButton
                                testId="wallet-connect"
                                buttonKind="ultimate-cta"
                                onClick={() => {
                                    sendAnalytics(createButtonEvent(ButtonEventName.setupUnlockConnectPressed));
                                    changeWallet();
                                }}
                            >
                                Connect
                            </StandardButton>
                            <DifferentWalletButton analyticsEvent={buttonEventName} />
                        </StandardLinkRouteButtonsFooter>
                    </>
                ))}

            {wallet &&
                registration &&
                currentStep === 'setupImx' &&
                (enableWalletConnect ? (
                    <>
                        <section>
                            <VerticalSpace bottom="large">
                                <SectionHeading>Set up Immutable X Key</SectionHeading>
                            </VerticalSpace>
                            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                                A unique key will be linked to the following wallet:
                            </ParagraphText>

                            <VerticalSpace top="large">
                                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                                    <InlineEllipsizedWalletLine
                                        fillColor={colors.blue[300]}
                                        fontSize="small"
                                        walletAddress={wallet.address}
                                        fontWeight="bold"
                                        className={styles.ellipsizedWalletLine}
                                    />{' '}
                                    <ChangeWalletButton
                                        providerPreference={providerPreference}
                                        onClick={() => changeWallet(connectWallet)}
                                    />
                                </ParagraphText>
                            </VerticalSpace>

                            <VerticalSpace top="large">
                                <ParagraphText fillColor={colors.light[100]} fontSize="small" fontWeight="bold">
                                    If you already did this, check you have the correct wallet address selected.
                                </ParagraphText>
                            </VerticalSpace>
                        </section>

                        <StandardLinkRouteButtonsFooter>
                            <VerticalSpace top="large" bottom="large">
                                <StandardButton
                                    testId="setup-imx"
                                    buttonKind="ultimate-cta"
                                    onClick={() => {
                                        sendAnalytics(createButtonEvent(ButtonEventName.setupKeySetUpPressed));
                                        register(registration, wallet);
                                    }}
                                >
                                    Set up key
                                </StandardButton>
                            </VerticalSpace>
                        </StandardLinkRouteButtonsFooter>
                    </>
                ) : (
                    <>
                        <section>
                            <VerticalSpace bottom="large">
                                <SectionHeading>Set up Immutable X Key</SectionHeading>
                            </VerticalSpace>
                            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                                A unique <strong style={{ color: colors.light[100] }}>Immutable X Key</strong> will be
                                linked to your wallet, allowing you to trade on Immutable X.
                            </ParagraphText>

                            <VerticalSpace top="large">
                                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                                    Connected wallet{' '}
                                    <ChangeWalletButton
                                        providerPreference={providerPreference}
                                        onClick={() => changeWallet(connectWallet)}
                                    />
                                    :
                                    <EllipsizedWalletLine
                                        fillColor={colors.blue[300]}
                                        fontSize="small"
                                        walletAddress={wallet.address}
                                        fontWeight="bold"
                                        className={styles.ellipsizedWalletLine}
                                    />
                                </ParagraphText>
                            </VerticalSpace>

                            <VerticalSpace top="large">
                                <ParagraphText fillColor={colors.light[100]} fontSize="small" fontWeight="bold">
                                    If you already did this, check you have the correct wallet address selected.
                                </ParagraphText>
                            </VerticalSpace>
                        </section>

                        <StandardLinkRouteButtonsFooter>
                            <Tickbox
                                labelText={
                                    <SimpleText fillColor={colors.light[300]} fontSize="tag">
                                        I agree to the{' '}
                                        <StyledLink
                                            fillColor={colors.light[700]}
                                            fontWeight="bold"
                                            fontSize="tag"
                                            onClick={() => {
                                                sendAnalytics(createLinkEvent(LinkEventName.setupKeyTermsPressed));
                                                window.open(TERMS_URL_HREF, '_blank');
                                            }}
                                        >
                                            Terms &amp; Conditions
                                        </StyledLink>
                                    </SimpleText>
                                }
                                onChange={(value) => {
                                    changeAgreeToTnc(value);
                                }}
                            />
                            <VerticalSpace top="large" bottom="large">
                                <StandardButton
                                    testId="setup-imx"
                                    buttonKind="ultimate-cta"
                                    disabled={!agreeToTnC}
                                    onClick={() => {
                                        sendAnalytics(createButtonEvent(ButtonEventName.setupKeySetUpPressed));
                                        register(registration, wallet);
                                    }}
                                >
                                    Connect
                                </StandardButton>
                            </VerticalSpace>
                        </StandardLinkRouteButtonsFooter>
                    </>
                ))}

            {wallet && currentStep === 'register' && (
                <section>
                    <IndeterminateHorizontalLines />
                    <VerticalSpace bottom="medium">
                        <SectionHeading>Setup in progress...</SectionHeading>
                    </VerticalSpace>
                    <VerticalSpace bottom="small">
                        <ParagraphText fillColor={colors.light[100]} fontSize="small" fontWeight="bold">
                            This may take some time.
                        </ParagraphText>
                    </VerticalSpace>
                    <VerticalSpace bottom="small">
                        <ParagraphText fillColor={colors.light[300]} fontSize="small">
                            We are sending a transaction to the Ethereum mainchain to{' '}
                            <strong style={{ color: colors.light[100] }}>
                                link your unique Immutable X Key with your Ethereum wallet
                            </strong>{' '}
                            <InlineEllipsizedWalletLine
                                fillColor={colors.blue[300]}
                                fontSize="small"
                                walletAddress={wallet.address}
                                fontWeight="bold"
                            />
                            .
                        </ParagraphText>
                    </VerticalSpace>
                    <VerticalSpace bottom="small">
                        <ParagraphText fillColor={colors.light[300]} fontSize="small">
                            This provides you with the security of Ethereum for all your transactions in Immutable X.
                        </ParagraphText>
                    </VerticalSpace>
                    <VerticalSpace bottom="small">
                        <ParagraphText fillColor={colors.light[300]} fontSize="small">
                            You will need access to your Ethereum wallet private key to verify your identity in
                            Immutable X.
                        </ParagraphText>
                    </VerticalSpace>
                    <ParagraphText fontSize="small" fontWeight="bold" fillColor={colors.yellow[100]}>
                        If you lose access to your wallet we will not be able to recover your assets in Immutable X.
                    </ParagraphText>
                </section>
            )}

            {wallet && registration && currentStep === 'complete' && setupComplete() && (
                <>
                    <section>
                        <ExplodingCreditCard />
                        <VerticalSpace bottom="large">
                            <SectionHeading>Setup Complete!</SectionHeading>
                        </VerticalSpace>
                        <ParagraphText fillColor={colors.light[300]} fontSize="small">
                            Registration of your wallet{' '}
                            <InlineEllipsizedWalletLine
                                fillColor={colors.blue[300]}
                                fontSize="small"
                                walletAddress={wallet.address}
                                fontWeight="bold"
                            />{' '}
                            with Immutable X has been completed.
                        </ParagraphText>
                        <VerticalSpace top="large">
                            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                                This window will appear each time you make a transaction on Immutable X.
                            </ParagraphText>
                        </VerticalSpace>
                        <VerticalSpace top="large">
                            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                                <strong style={{ color: colors.yellow[100] }}>Important:</strong> Only sign requests if
                                you’ve initiated the action with Immutable X.
                            </ParagraphText>
                        </VerticalSpace>
                    </section>
                    <StandardLinkRouteButtonsFooter>
                        <VerticalSpace bottom="large" top="large">
                            <StandardButton
                                testId="wallet-setup-complete"
                                buttonKind="primary"
                                onClick={() => {
                                    sendAnalytics(createButtonEvent(ButtonEventName.setupCompleteFinishPressed));
                                    finish(wallet.address, registration.starkPublicKey);
                                }}
                            >
                                Close
                            </StandardButton>
                        </VerticalSpace>
                    </StandardLinkRouteButtonsFooter>
                </>
            )}
        </StandardLinkRouteContainer>
    );
};
