import { css } from '@emotion/css';
import { BigNumber } from '@ethersproject/bignumber';
import { Web3Provider } from '@ethersproject/providers';
import {
    colors,
    ExplodingCircleWithTick,
    FlexLayout,
    Icon,
    IndeterminateHorizontalLines,
    layoutHelpers,
    measurements,
    OuterSpace,
    ParagraphText,
    SectionHeading,
    StyledLink,
    VerticalSpace,
} from '@imtbl/design-system';
import {
    assertEither,
    assertNever,
    ERC20TokenType,
    ERC721TokenType,
    errorsToError,
    ETHTokenType,
    ImmutableMethodResults,
    ImmutableXClient,
    LINK_MESSAGE_TYPE,
    LinkParamsCodecs,
    LinkParamsF,
    LinkResults,
    messageTypes,
    MintableERC20TokenType,
    MintableERC721TokenType,
    PositiveBigNumber,
    Token,
} from '@imtbl/imx-sdk';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import React, { useCallback, useEffect } from 'react';

import { useLaunchDarklyFlags } from '../../context/LaunchDarkly';
import { ErrorWithCode } from '../../errors';
import { useTokensList } from '../../hooks/useTokensList.hook';
import { Messages } from '../../i18n';
import {
    completeWithdrawal,
    DispatchSetError,
    DispatchSetLoading,
    EthNetwork,
    flatTokenToToken,
    getBalanceForTokenType,
    getERC20TokenMetaData,
    getTokenAsset,
    isEthOrERC20TokenType,
    LinkClientConfig,
    NotifyMethod,
    ParentWindow,
    requiresSeparateRegistrationStep,
    tokenToTokenWithAmount,
    TokenWithAmount,
} from '../../lib';
import { sendAnalytics } from '../../lib/analytics/send-analytics';
import { ButtonEventName } from '../../lib/analytics/types';
import { createButtonEvent } from '../../lib/analytics/utils';
import { FEATURE_FLAG } from '../../lib/featureFlags';
import { DEFAULT_ETH_TOKEN_IMAGERY } from '../../utils/constants';
import { AccountActivationFee } from '../AccountActivationFee';
import { Action } from '../Action';
import { EtherscanLink } from '../EtherscanLink';
import { TokenDetailsDisplayRow } from '../TokenDetailsDisplayRow';
import styles from './index.module.css';

export type CompleteWithdrawalProps = {
    config: LinkClientConfig;
    parent: ParentWindow;
    provider: Web3Provider;
    messages: Messages;
    setErrorLog: DispatchSetError;
    setLoading: DispatchSetLoading;
    ethNetwork: EthNetwork;
};

export type CompleteWithdrawalInput = {
    client: ImmutableXClient;
    token: Token;
    tokenWithAmount: TokenWithAmount;
    asset?: ImmutableMethodResults.ImmutableAsset;
    withdrawable: PositiveBigNumber;
    isRegistered: boolean;
};

export const CompleteWithdrawal = ({
    config,
    parent,
    provider,
    messages,
    setErrorLog,
    setLoading,
    ethNetwork,
}: CompleteWithdrawalProps) => {
    const flags = useLaunchDarklyFlags();
    const separateRegisterAndWithdrawalEth = flags[FEATURE_FLAG.SEPARATE_REGISTER_AND_WITHDRAWAL_ETH];

    const { tokens, error } = useTokensList({ config });
    const hasTokensList = tokens.length > 0;

    useEffect(() => {
        if (!hasTokensList) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [hasTokensList, setLoading]);

    useEffect(() => {
        if (error) {
            setErrorLog(error, messages.generalErrorMessage([messages.failedToRetrieveTokenList(error.message)]));
            setLoading(false);
        }
    }, [error, setErrorLog, setLoading]);

    const loadInput = (params: LinkParamsF.CompleteWithdrawal) => {
        return pipe(
            TE.bindTo('client')(
                ImmutableXClient.buildF({
                    ...config,
                    signer: provider.getSigner(),
                }),
            ),
            TE.bind('address', ({ client }) => TE.of(client.address)),
            TE.chainFirst(({ client, address }) => {
                TE.fromIO(() =>
                    console.log(`Checking if ${address} is registered with stark key ${client.starkPublicKey}`),
                );
                return pipe(
                    assertEither(!address, messages.completeWithdrawal.walletAddressRetrievingFailed),
                    TE.fromEither,
                );
            }),
            TE.bind('isRegistered', ({ client }) => TE.fromTask(client.isRegisteredStarkF(client.starkPublicKey))),
            TE.bind('tokenWithDecimals', ({ client }) =>
                client.getTokenF(params.type === ERC20TokenType.ERC20 ? { tokenAddress: params.tokenAddress } : {}),
            ),
            TE.bind('token', ({ tokenWithDecimals }) =>
                TE.of(flatTokenToToken(params, parseInt(tokenWithDecimals.decimals))),
            ),

            TE.bind('asset', ({ token, client }) => getTokenAsset(token)(client)),
            TE.bind('balance', ({ client, address, token }) => getBalanceForTokenType(token, client, address)),
            TE.bind('withdrawable', ({ balance, token }) => {
                switch (token.type) {
                    case ERC721TokenType.ERC721:
                        return pipe(
                            PositiveBigNumber.decode(BigNumber.from(1)),
                            E.mapLeft(errorsToError),
                            TE.fromEither,
                        );
                    case ERC20TokenType.ERC20:
                    case ETHTokenType.ETH:
                        return pipe(
                            assertEither(balance.withdrawable.isZero(), messages.completeWithdrawal.nothingToWithdraw),
                            E.chain(() =>
                                pipe(PositiveBigNumber.decode(balance.withdrawable), E.mapLeft(errorsToError)),
                            ),
                            TE.fromEither,
                        );
                    case MintableERC20TokenType.MINTABLE_ERC20:
                        return TE.left(new Error('Not implemented'));
                    case MintableERC721TokenType.MINTABLE_ERC721:
                        return TE.left(new Error('Not implemented'));
                    default:
                        return assertNever(token);
                }
            }),
            TE.bind('tokenWithAmount', ({ withdrawable, token }) =>
                pipe(tokenToTokenWithAmount(withdrawable)(token), E.mapLeft(errorsToError), TE.fromEither),
            ),
        );
    };

    const execute = (
        { token, isRegistered, client }: CompleteWithdrawalInput,
        notify: NotifyMethod,
    ): TE.TaskEither<ErrorWithCode, LinkResults.CompleteWithdrawal> => {
        const requiresSeparateTransaction = requiresSeparateRegistrationStep(
            isRegistered,
            separateRegisterAndWithdrawalEth,
            token,
        );

        // TODO: Critical events in chain to be tested as part of 'IMX-2431'.
        sendAnalytics(createButtonEvent(ButtonEventName.completeWdrawNotifiedProceedPressed));
        setLoading(true);
        return pipe(
            completeWithdrawal(client, provider, token, requiresSeparateTransaction, notify),
            TE.bimap(
                (e) => {
                    setLoading(false);
                    return e;
                },
                (result) => {
                    setLoading(false);
                    return result;
                },
            ),
        );
    };

    const showDetailsStart = ({ tokenWithAmount: details, isRegistered, asset }: CompleteWithdrawalInput) => {
        const tokenMetaData = getERC20TokenMetaData(details, tokens);
        const requiresSeparateTransaction = requiresSeparateRegistrationStep(
            isRegistered,
            separateRegisterAndWithdrawalEth,
            details.token,
        );

        return (
            <>
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    Please confirm the assets for withdrawal:
                </ParagraphText>
                <OuterSpace top={measurements.SpacingTeeShirtAmounts.large}>
                    <TokenDetailsDisplayRow
                        testId="completeWithdrawalTokenDetails"
                        tokenType={details.token.type}
                        asset={asset}
                        tokenDisplayAmount={isEthOrERC20TokenType(details.token.type) ? details.amount : undefined}
                        transactionCostTokenImage={tokenMetaData?.image_url || DEFAULT_ETH_TOKEN_IMAGERY}
                        tokenMetadata={tokenMetaData}
                        className={css`
                            border: none;
                        `}
                    />
                </OuterSpace>
                <FlexLayout alignItems="stretch" justifyContent="flex-end" flexDirection="column" flexGrow={1}>
                    {!isRegistered && (
                        <AccountActivationFee
                            action="Withdrawal"
                            requiresSeparateTransaction={requiresSeparateTransaction}
                        />
                    )}
                    <FlexLayout
                        alignSelf="center"
                        justifyContent="center"
                        paddingTop={layoutHelpers.gridUnits(3)}
                        paddingBottom={layoutHelpers.gridUnits(3)}
                    >
                        <Icon ligature="misc_alert" fillColor={colors.yellow[100]} iconSize="x-large" />
                        <ParagraphText
                            fontSize="tag"
                            fontWeight="bold"
                            className={styles.prepare}
                            fillColor={colors.light[300]}
                        >
                            Do not begin a withdrawal if one is in progress{' '}
                            <StyledLink
                                fontSize="tag"
                                fontWeight="bold"
                                fillColor={colors.light[700]}
                                href="https://support.immutable.com/hc/en-us/articles/9455685400079-How-to-withdraw-your-L2-assets-into-your-L1-Wallet"
                                underline={false}
                                target="_blank"
                            >
                                (i)
                            </StyledLink>
                        </ParagraphText>
                    </FlexLayout>
                </FlexLayout>
            </>
        );
    };

    const showDetailsInProgress = (_: CompleteWithdrawalInput, tx = 'test') => {
        return (
            <>
                <IndeterminateHorizontalLines />
                <VerticalSpace bottom="small">
                    <SectionHeading>{messages.completeWithdrawal.title.inProgress}</SectionHeading>
                </VerticalSpace>
                <VerticalSpace bottom="small">
                    <ParagraphText fillColor={colors.light[300]} fontSize="small">
                        Your withdrawal is confirming with the Ethereum blockchain and can be tracked on{' '}
                        <EtherscanLink ethNetwork={ethNetwork} txId={tx}>
                            Etherscan
                        </EtherscanLink>
                        .
                    </ParagraphText>
                </VerticalSpace>

                <VerticalSpace bottom="small">
                    <ParagraphText fillColor={colors.light[100]} fontSize="small" fontWeight="bold">
                        This may take some time.
                    </ParagraphText>
                </VerticalSpace>

                <VerticalSpace bottom="small">
                    <ParagraphText fillColor={colors.yellow[100]} fontSize="small" fontWeight="bold">
                        Do not begin another withdrawal from Immutable X while this withdrawal is in progress...{' '}
                        <StyledLink
                            fontSize="small"
                            href="https://support.immutable.com/hc/en-us/articles/9455685400079-How-to-withdraw-your-L2-assets-into-your-L1-Wallet"
                            target="_blank"
                            anchorDomProps={{ rel: 'noreferrer noopener' }}
                        >
                            Learn more
                        </StyledLink>
                    </ParagraphText>
                </VerticalSpace>
            </>
        );
    };

    const showDetailsDone = (_: CompleteWithdrawalInput) => (
        <>
            <ExplodingCircleWithTick />
            <VerticalSpace bottom="small">
                <SectionHeading>{messages.completeWithdrawal.title.done}</SectionHeading>
            </VerticalSpace>
            <ParagraphText fillColor={colors.light[300]} fontSize="small">
                Your withdrawal is on its way into your{' '}
                <strong style={{ color: colors.light[100] }}>Ethereum wallet</strong>.
            </ParagraphText>

            <VerticalSpace top="large">
                <ParagraphText fillColor={colors.light[300]} fontSize="small">
                    You can check the status of the transaction via your Ethereum wallet.
                </ParagraphText>
            </VerticalSpace>
        </>
    );

    const onFinish = useCallback(() => {
        // TODO: Critical events in chain to be tested as part of 'IMX-2431'.
        sendAnalytics(createButtonEvent(ButtonEventName.completeWdrawConfirmedFinishPressed));
    }, []);

    const onDenyClick = useCallback(() => {
        parent.postMessage({ type: LINK_MESSAGE_TYPE, message: messageTypes.close }, '*');
    }, []);

    if (!hasTokensList) {
        return null;
    }

    return (
        <Action
            parent={parent}
            title={messages.completeWithdrawal.title}
            paramsDecoder={LinkParamsCodecs.CompleteWithdrawal.decode}
            loadInput={loadInput}
            execute={execute}
            showDetails={{
                start: showDetailsStart,
                inProgress: showDetailsInProgress,
                done: showDetailsDone,
            }}
            hasTitleInDetails={{
                start: false,
                inProgress: true,
                done: true,
            }}
            setErrorLog={setErrorLog}
            onFinishClick={onFinish}
            onDenyClick={onDenyClick}
        />
    );
};
